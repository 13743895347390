import React from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import { Link } from 'gatsby'
import PagePadding from '../components/PagePadding/PagePadding'

const NotFound = () => {
	return (
		<SecondaryLayout>
			<PagePadding>
				<h1>Pagina niet gevonden</h1>
				<p>
					Het lijkt er op dat deze pagina niet meer bestaat of dat er
					iets is misgegaan.
				</p>
				<p>
					<Link to={'/'}>Terug naar home</Link>
				</p>
			</PagePadding>
		</SecondaryLayout>
	)
}

export default NotFound
